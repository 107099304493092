import React, { useState, useEffect } from "react";

import XGridDemo from "components/UI/Grid/Xgrid";
import TransitionModal from "components/UI/Modals/Modal";
import { Buttons, Filters, HeaderButton } from "components";

import UserBOFilters from "./UsuariosBOFilters";

import { CONActivar, LupaIcon, ReloadIcon, Revisar } from "assets/icons";

import { usuariosBO as titles } from "consts/tableTitles";
import { ROUTES } from "consts/routes";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    modalData,
    snackbarData,
    routeInfo,
} from "recoil/GlobalState";

import EndUserModal from "./UsuariosBOModal";
import LocationModal from "./Location/LocationModal";
import CuilCuitModal from "./CUIL-CUIT/Cuil-CuitModal";
import IdentityModal from "./Identity/IdentityModal";

import styles from "styles/pages/Tables.module.scss";
import OmniModal from "./Omni/OmniModal";
import ConnectionModal from "./Actions/ConnectionModal";
import { ACTIONS } from "consts/actions";
import { ESTADO_REGISTRO_COD } from "consts/estadoRegistroCod";

export default function UsuariosBO() {
    const [isLoading, setIsLoading] = useState(false);
    const [procede, setProcede] = useState(null);
    const [endUsersLocalState, setEndUsersLocalState] = useState([]);
    const [filterParams, setFilterParams] = useState({});
    const [userStatus, setUserStatus] = useState([]);
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [updateListCloseModal, setUpdateListCloseModal] = useState(false);

    const [row, setRow] = useRecoilState(rowSelected);
    const [modalDetails, setModalDetails] = useRecoilState(modalData);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const credentials = useRecoilValue(userCredentials);

    async function retrieveUserStatus() {
        setIsModalLoading(true);

        const body = {
            service: "UsuarioStatusGet",
            params: {
                usuarioId: row.row.id,
                rolCod: "E",
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setUserStatus(result);
        setIsModalLoading(false);
    }

    const handleUpdateTable = (params) => {
        const parameters = params ? params : filterParams;

        formatUsers(parameters);

        setRow({ status: false, row: {} });
    };

    async function formatUsers({
        usuarioApellidos = null,
        usuarioNroDeDocumentoX = null,
        registroId = null,
        usuarioStatusRegistro = null,
        paisId = null,
    }) {
        setIsLoading(true);

        const body = {
            service: "EndUserBOListGet",
            params: {
                usuarioApellidos,
                usuarioNroDeDocumentoX,
                registroId,
                usuarioStatusRegistro,
                paisId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const endUsersPartial = result
            /*.sort((a, b) =>
                a.usuarioFechaAlta && b.usuarioFechaAlta
                    ? parseInt(
                        b.usuarioFechaAlta.substring(0, 10).replace(/-/g, "")
                    ) -
                    parseInt(
                        a.usuarioFechaAlta.substring(0, 10).replace(/-/g, "")
                    )
                    : a.usuarioApellidos - b.usuarioApellidos
            )*/
            .map((item) => {
                const {
                    usuarioId,
                    usuarioApellidos,
                    usuarioNombres,
                    tipoDeDocumentoCod,
                    usuarioNroDeDocumentoX,
                    usuarioSexo,
                    usuarioStatusRegistro,
                    usuarioFechaAlta,
                    usuarioVersion,
                    cuentaOK,
                    cuentaBO,
                    cuentaRE,
                    paisNombre,
                    statusRENAPER,
                    usuarioDNIMatchRENAPER
                } = item;

                return {
                    id: usuarioId,
                    usuarioVersion,
                    aprobados: cuentaOK,
                    enEspera: cuentaBO,
                    rechazados: cuentaRE,
                    statusRENAPER,
                    usuarioDNIMatchRENAPER,
                    [titles[0]]: usuarioApellidos,
                    [titles[1]]: usuarioNombres,
                    [titles[2]]: paisNombre,
                    [titles[3]]: tipoDeDocumentoCod,
                    [titles[4]]: usuarioNroDeDocumentoX ? parseInt(usuarioNroDeDocumentoX).toLocaleString('es') : '',
                    [titles[5]]: usuarioSexo,
                    [titles[6]]: usuarioFechaAlta
                        ? usuarioFechaAlta.slice(0, 10)
                        : null,
                    [titles[7]]: usuarioStatusRegistro === 'OK' ? 'W' : usuarioStatusRegistro,
                };
            });

        setEndUsersLocalState(endUsersPartial);

        setIsLoading(false);
    }

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
        handleUpdateTable();
    }, []);

    const openModal = () => {
        retrieveUserStatus();
    };

    const closeModal = (update = false) => {
        if (update || updateListCloseModal) {
            handleUpdateTable();
            setUpdateListCloseModal(false);
        }
        setModalDetails({ ...modalDetails, isOpen: false });
    };

    const openTransitionModal = (procede) => {
        setProcede(procede);
    };

    const closeTransitionModal = () => {
        setProcede(null);
    };

    const updateModalList = () => {
        retrieveUserStatus();
        setUpdateListCloseModal(true);
    };

    const renaperStatusCheck = async (userId, usuarioVersion) => {
        setIsLoading(true);

        const body = {
            service: "RENAPERStatusCheck",
            params: {
                usuarioId: userId,
                usuarioVersion
            },
        };

        await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo,
            'El proceso terminó exitosamente',
            true
        );

        handleUpdateTable();

        setIsLoading(false);
    };

    return (
        <section className={styles.section}>
            <TransitionModal customOnClose={closeTransitionModal}>
                {action === ACTIONS.DETAIL && (
                    <ConnectionModal
                        usuarioId={row.row.id}
                        closeModal={closeModal}
                    />
                )}
                {action === ACTIONS.SHOW &&
                    <>
                        {!procede && (
                            <EndUserModal
                                isLoading={isModalLoading}
                                openEdit={openTransitionModal}
                                closeModal={closeModal}
                                userStatus={userStatus}
                            />
                        )}
                        {(["EU0002", "EU0003", "EU0004"].includes(procede)) && (
                            <IdentityModal
                                closeModal={closeTransitionModal}
                                closAllModal={closeModal}
                                updateModalList={updateModalList}
                                userId={row.row.id}
                            />
                        )}
                        {procede === "EU0005" && (
                            <LocationModal
                                closeModal={closeTransitionModal}
                                updateModalList={updateModalList}
                                userId={row.row.id}
                            />
                        )}
                        {procede === "EU0006" && (
                            <CuilCuitModal
                                closeModal={closeTransitionModal}
                                updateModalList={updateModalList}
                                userId={row.row.id}
                            />
                        )}
                        {procede === "EU0015" && (
                            <OmniModal
                                closeModal={closeTransitionModal}
                                updateModalList={updateModalList}
                                userId={row.row.id}
                            />
                        )}
                    </>
                }
            </TransitionModal>

            <Buttons filtersApplied>
                <Filters>
                    <UserBOFilters
                        handleSubmit={handleUpdateTable}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                    />
                </Filters>
                <span>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Ver Detalle"}
                                setOpen={openModal}
                                icon={LupaIcon}
                                data={{
                                    action: ACTIONS.SHOW,
                                    title: "Estado de la Documentación",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Ver conexiones"}
                                x
                                icon={CONActivar}
                                data={{
                                    action: ACTIONS.DETAIL,
                                    title: "Conexiones",
                                    button: "Confirmar",
                                }}
                            />
                            {
                                row.row.statusRENAPER === ESTADO_REGISTRO_COD.en_espera &&
                                !(row.row.usuarioDNIMatchRENAPER > 0) &&
                                <HeaderButton
                                    text={"Actualizar RENAPER"}
                                    icon={Revisar}
                                    handleClick={() => renaperStatusCheck(row.row.id, row.row.usuarioVersion)}
                                />
                            }
                        </>
                    ) : (
                        <HeaderButton
                            text={"Actualizar página"}
                            icon={ReloadIcon}
                            updateList={handleUpdateTable}
                        />
                    )}
                </span>
            </Buttons>

            <XGridDemo
                rows={endUsersLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
